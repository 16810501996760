// =============================================================================
//
//  wishlist
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

@import '../../../../themes/default/components/wishlist/wishlist';

.wishlist {
    display: block;
    margin: 0 -1rem;

    &-icon {
        font-size: $wishlist-icon__font-size--large;
    }

    &-count-badge {
        position: absolute;
        right: 0;
    }

    .product-tile {
        .wishlist-wrapper {
            right: 0;
        }
    }
}

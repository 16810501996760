
$grassy-green: #37b700;
$fern-green: #429642;
$light-green: #f1efe2;
$pine: #234023;
$yellow-green: #dee499;

$minicart-quantity__positon: relative;
$minicart-quantity__top: -0.6rem;
$minicart-quantity__left: -0.5rem;
$minicart-quantity__display: inline-block;
$minicart-quantity__width: 1.9rem;
$minicart-quantity__height: 1.9rem;
$minicart-quantity__background-color: $white;
$minicart-quantity__border-radius: 50%;
$minicart-quantity__color: $orange;
$minicart-quantity__line-height: 1;
$minicart-quantity__text-align: center;

$cart-box-item__margin-bottom: $base-margin;
$valid-cart-error__position: relative;
$valid-cart-error__padding: $base-padding * 1.2 $base-padding * 3.6 $base-padding * 1.2 $base-padding;
$valid-cart-error__margin-bottom: $base-margin;
$valid-cart-error__background: $orange-dash;
$valid-cart-error__color: $black;

$valid-cart-error-close__position: absolute;
$valid-cart-error-close__right: 1.6rem;
$valid-cart-error-close__color: $black;
$valid-cart-error-close__top: 50%;
$valid-cart-error-close__height: 2.1rem;
$valid-cart-error-close__color: $black;

$cart-box-item__width: 2.5rem;
$cart-box-item__position: relative;
$cart-box-item__top: 0.1rem;
$cart-box-item__height: 2.5rem;
$cart-box-item__background: $orange;
$cart-box-item__border-radius: 50%;
$cart-box-item__color: $white;

$cart-box-item-title__margin-left: $base-margin * 1.5;
$cart-box-item-title__color: $black;
$cart-box-item-title__line-height: 1.5;

$cart-box-items__margin-bottom: $base-margin * 2;

$cart-box-item-title__font-weight: $bolder;
$cart-box-item-title__line-height__margin-left: $base-margin * 0.5;

$added-to-cart-box__position: absolute;
$added-to-cart-box__z-index: 10;
$added-to-cart-box__top: 5.8rem;
$added-to-cart-box__width: 30rem;
$added-to-cart-box__min-height: 20rem;
$added-to-cart-box__padding: $base-padding * 2 $base-padding $base-padding $base-padding;
$added-to-cart-box__background: $white;
$added-to-cart-box__box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.23);

$added-to-cart-box-content__height: 100%;
$added-to-cart-box-content__overflow-y: auto;

$added-to-cart-box-arrow__position: absolute;
$added-to-cart-box-arrow__top: -1.6rem;
$added-to-cart-box-arrow__right: 3rem;
$added-to-cart-box-arrow__width: 1rem;
$added-to-cart-box-arrow__height: 0.5rem;
$added-to-cart-box-arrow__margin: $base-margin * 0 $base-margin * 0.3;
$added-to-cart-box-arrow--before__border-width: $border * 0 $border * 10 $border * 15;
$added-to-cart-box-arrow--before__border-style: solid;
$added-to-cart-box-arrow--before__border-color: transparent;
$added-to-cart-box-arrow--before__border-bottom-color: rgba(0, 0, 0, 0.1);
$added-to-cart-box-arrow--after__top: 0.1rem;
$added-to-cart-box-arrow--after__border-bottom-color: $white;

$minicart-wrapper__position: fixed;
$minicart-wrapper__right: -100%;
$minicart-wrapper__width: 100%;
$minicart-wrapper__top: 0;
$minicart-wrapper__bottom: 0;
$minicart-wrapper__max-width: 37.5rem;
$minicart-wrapper__background: $white;
$minicart-wrapper__box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.23);
$minicart-wrapper__color: $black;

$sub-total-label__margin-bottom: $base-margin * 0.5;
$sub-total-label__color: $black;
$sub-total-label__text-align: right;

$sub-total__color: $black-two;
$sub-total__font-weight: $bold;
$grand-total__font-weight: $bold;

$checkout-btn__display: block;
$checkout-btn__width: 100%;
$checkout-btn__padding: $base-padding * 1.3 $base-padding * 2;
$checkout-btn__background: $orange;
$checkout-btn-hover__background: $orange-dash;
$checkout-btn__color: $white;
$checkout-btn__font-weight: $bolder;
$checkout-btn__text-align: center;

$minicart-products__margin-top: $base-margin * 1.5;
$minicart-products__padding: $base-padding * 1.5 $base-padding * 1.2;
$minicart-products__border-top: $border solid $pale-liliac;
$minicart-quantity-form__margin-top: $base-margin * 1;

$minicart-quantity-quantity-form-quantity__width: 2.4rem;
$minicart-quantity-quantity-form-quantity__height: 2.4rem;
$minicart-quantity-quantity-form-quantity__margin: $base-margin * 0 $base-margin * 0.6;

$minicart-content-header__padding: $base-padding * 1.2;
$minicart-content-header__border-bottom: $border solid $pale-liliac;

$minicart-content-title__color: $black;
$minicart-content-title__font-weight: $bolder;

$minicart-footer__padding: ($base-padding * 1.3) ($base-padding * 1.2) ($base-padding * 1) ($base-padding * 1.2);
$minicart-footer__background: #f4f4f4;

$minicart-footer-bottom__padding-top: $base-padding * 2;
$icon-footer__margin-right: $base-margin * 0.8;
$icon-footer__color: $pine;
$icon-footer__vertical-align: middle;

$minicart-footer-img__padding-right: $base-padding * 1.5;
$minicart-footer-img__border-right: $border solid rgba(0, 0, 0, 0.1);
$minicart-footer-img__margin-right: $base-margin * 1.5;

$footer-cards-img__margin-right: $base-margin * 0.5;

$minicart-product-tile__padding: $base-padding * 1 0;
$minicart-product-tile__border-bottom: $border solid $pale-liliac;

$minicart-bonus-product-tile__padding-top: $base-padding * 2;

$minicart-product-tile-remove__width: 1.6rem;
$minicart-product-tile-remove__height: 8.2rem;
$minicart-product-tile-remove__margin-right: $base-margin * 0.5;

$minicart-product-tile-image-wrapper__width: 8.2rem;
$minicart-product-tile-image__width: 8.2rem;
$minicart-product-tile-image__height: 8.2rem;

$minicart-total-saving__margin-bottom: $base-margin;

$minicart-total-saving-amount__color: #cd0000;

$cart-delete-confirmation-btn__color: $black;

$minicart-product-tile-price__margin-top: $base-margin * 2;
$minicart-product-tile-price-sales__color: $black;

$line-item-pricing-info__color: $brown-grey;

$line-item-total-price-amount__margin-left: $base-margin * 0.5;
$line-item-total-price-amount__color: $black;
$line-item-total-price-amount__font-weight: $bold;

$line-item-total-price-have-discount__height: 3.3rem;

$minicart-messages__padding: 0 $base-padding * 1.2;

$bonus-tile__position: absolute;
$bonus-tile__top: 0;
$bonus-tile__right: 0;
$bonus-tile__width: 4.3rem;
$bonus-tile__height: 2rem;
$bonus-tile__background: $red;
$bonus-tile__color: $white;

$msg-error__background: $orange-dash;
$msg-error__color: $white;
$msg-free-delivery__background: rgba($blue, 0.5);
$msg-bonus-product__background: rgba(#e3e3e3, 0.5);

$line-item-brand__color: $greyish-brown;

$checkout-continue__padding-top: $base-padding;
$checkout-continue__border-top: $border solid rgba($black, 0.1);


// =============================================================================
// LARGE
// =============================================================================

$minicart-quantity__top--large: -0.8rem;
$minicart-quantity__left--large: -0.8rem;
$minicart-quantity__width--large: 2rem;
$minicart-quantity__height--large: 2rem;

$minicart-footer__padding--large: ($base-padding * 1.3) ($base-padding * 1.2) ($base-padding * 2.3) ($base-padding * 1.2);
$minicart-footer-bottom__padding-top--large: $base-padding * 2.3;

$minicart-wrapper__max-width--large: 40rem;
$added-to-cart-box__width--large: 33rem;

$minicart-products-product-tile-description__margin--large: 0 0.5rem 0 1rem;

$sub-total-label__margin-bottom: $base-margin * 1.2;

$bonus-tile__top--large: -1.2rem;
$minicart-content-product__margin-bottom--large: $base-margin * 1.2;

$minicart-products-product-tile-__border-bottom--large: $border solid $very-light-pink;


// =============================================================================
//
//  Wishlist Variables
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================
// General
$wishlist-icon__color: $red;
$wishlist-inlist__color: $orange;

$wishlist-icon__font-size: 2rem;

$wishlist-wrapper-position__right: 1rem;
$wishlist-wrapper-position__right--portrait: 8rem;
$wishlist-wrapper-position__bottom: 1rem;

$wishlist-wrapper__bottom: 1rem;
$wishlist-wrapper__bottom--large: 10rem;
$wishlist-wrapper__left--large: 1rem;
$wishlist-wrapper__top--large: -3rem;

$wishlist-count-badge__top: 50%;
$wishlist-count-badge__right: 3rem;
$wishlist-count-badge__size: 2.1rem;
$wishlist-count-badge__background: $orange;
$wishlist-count-badge__color: $white;
$wishlist-count-badge__font-size: 1.4rem;
$wishlist-count-badge__line-height: 2.2rem;

$wishlist-remove-from-wishlist__font-size: 1.4rem;

$wishlist-icon__font-size--large: 2.1rem;

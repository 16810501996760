// =============================================================================
//
//  Tabs Desktop
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================


.tabs {
    &-header {
        right: 0;
        left: 0;
        width: 100%;
        margin: 0;
    }

    &-header>.item {
        @include get-font('medium');
        padding: 0;
        margin-right: $base-margin * 3.5;
    }
}

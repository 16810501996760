// =============================================================================
//
//  Mixin Media Queries
//
//  @version    0.1.0
//  @package    Bodyman
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

// -----------------------------------------------------------------------------
//  Screen sizes
// -----------------------------------------------------------------------------

@mixin media-screen-xl {
    @media screen and (min-width: $desktop-width-x-large) {
        @content;
    }
}

@mixin media-screen-lg {
    @media screen and (min-width: $desktop-width) {
        @content;
    }
}

@mixin media-screen-md {
    @media screen and (min-width: $tablet-width) {
        @content;
    }
}

@mixin media-screen-sm {
    @content;
}

// =============================================================================
//
//  Product Tiles Variables
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

$product-tile__padding: 0 0 $base-padding;
$product-tile__border-top: $border solid $pale-liliac;
$product-tile-description-cta_padding-top: $base-padding;

$product-tile-image__container__width: 10rem;
$product-tile-image__container__height: 10rem;
$product-tile-image__container__background-color: $white;

$product-tile-image__width: 10rem;
$product-tile-image__min-width: 10rem;
$product-tile-image__height: 10rem;

$product-tile-description__margin: 0 ($base-margin * 0.5) 0 ($base-margin);

$product-tile-promotions__margin-bottom: $base-margin * 0.2;

$product-tile-promotions-promotion__border-right: $border solid rgba(0, 0, 0, 0.2);
$product-tile-promotions-promotion-sale__color: $red;
$product-tile-promotions-promotion-sale__background-color: $white;

$product-tile-promotions-span__padding: $base-padding * 0.4;
$product-tile-promotions-new__margin-left: $base-margin * 0.3;
$product-tile-promotions-new__padding-right: $base-margin * 0.2;
$product-tile-promotions-span__margin-right: $base-margin * 0.1;

$product-tile-promotions-right__border-right: $border solid rgba(0, 0, 0, 0.2);

$product-tile-promotion-wrapper__left--large: 2rem;

$product-tile-promotion-mobile__color: $black-two;
$product-tile-promotion-sale-mobile__color: $red-signal;

$product-tile-promotions-hide-span__margin-bottom: $base-margin * 0.3;

$product-tile-name-link__color: $greyish-brown-two;

$product-tile-name__letter-spacing: -0.02rem;

$product-tile-brand__opacity: 0.5;
$product-tile-brand__color: $greyish-brown-two;

$product-tile-rating__margin-top: $base-margin * 0.3;

$product-tile-cta-price-adjusted__color: $red;
$product-tile-cta-price-adjusted__font-weight: $bold;
$product-tile-cta__min-width: 9.5rem;
$wishlist-wrapper-position__right--large: 0;
$wishlist-wrapper-position__right--small: 9.5rem;

$product-tile-cta-price-non-adjusted__color: $brown-grey;

$product-tile-cta-price-sales__font-weight: $bold;

$product-tile-buy__padding: ($base-padding * 0.8) ($base-padding * 1);

$product-tile-button-quickview-hover__color: $red;

$product-tile-promotion-wrapper-promotion__display: block;
$product-tile-promotion-wrapper-promotion__width: 100%;
$product-tile-promotion-wrapper-promotion__padding-right: $base-padding !important;
$product-tile-promotion-wrapper-promotion__padding-left: $base-padding !important;

$prices-price-ref-price-discount__color: $red;

$card-view-position__bottom: -4rem;
$card-view-gmf-stars__width: 8rem;
$card-view-rating__height: 3rem;
$card-view-rating__margin: $base-margin * 0.5 0;
$card-view-ref-wrapper__height: 3rem;

//-----------------------------------------------------------------------------
// Screen Large
// -----------------------------------------------------------------------------

$product__margin-bottom--large: $base-margin * 2;

$product-tile__padding--large: 0 0 $base-padding * 1.4 $base-padding * 2;
$product-tile__margin--large: $base-margin * 0  $base-margin * 1;

$product-tile-promotions__top--large: 0.5rem;

$product-tile-promotion__min-width--large: 7.7rem;
$product-tile-promotion__padding--large: $base-padding * 0.35;

$product-tile-promotions-promotion-span__padding--large: $base-padding * 0.5;
$product-tile-promotions-promotion-span__margin-top--large: $base-margin * 0.5;
$product-tile-promotions-promotion-span__background-color--large: $black;
$product-tile-promotions-promotion-span__color--large: $white;

$product-tile-promotions-sale__top--large: 2.5rem;

$product-tile-rating__top--large: 0.75rem;
$product-tile-rating__right--large: 0.75rem;
$product-tile-rating__height: 1.4rem;

$product-tile-description__margin--large: ($base-margin * 0.3) 0 0 0;

$product-tile-cta__margin-top--large: $base-margin * 0.6;

$product-tile-cta-price-adjusted__color--large: $red;
$prices-price-ref-price-discount__color: $red;

$product-tile-buy__padding--large: ($base-padding * 0.9) ($base-padding * 2);
$product-tile-name__line-height--large: 1.31;

$product-tile-name__letter-spacing--large: -0.05rem;

$price-ref__margin-top: 0.5rem;
$price-ref__margin-bottom: 1rem;
$price-ref__margin-top--large: 1rem;
$price-ref__min-height: 3rem;

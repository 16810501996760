// =============================================================================
//
//  Header Menu Navigation Large
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

@media screen and (min-width: $desktop-width-x-large) {
    .menu {
        height: $header-navigation__height--large;

        &-open-overlay {
            position: fixed;
            z-index: 10;
            top: 0;
            left: 0;
            width: $header-navigation-open-overlay__width--large;
            height: $header-navigation-open-overlay__height--large;
            background-color: $header-navigation-open-overlay__background-color--large;
        }

        &-main-container {
            position: static;
            overflow: hidden;
            width: $header-elements-full__width;
            height: $header-elements-full__height;
            padding: 0;
            background-color: $header-navigation-main-container__background-color--large;
        }

        &-main {
            a {
                display: unset;
                text-decoration: none;

                &:hover {
                    color: $a-hover__color;
                }
            }
        }

        &-actions {
            display: none;
        }

        &-category-list {
            position: static;
        }

        &-category-level-1,
        &-category-level-2,
        &-pages-list {
            @include flexbox();
            @include flex-direction(row);
            @include flex-wrap(wrap);

            li {
                a {
                    &::after {
                        content: none;
                    }
                }
            }
        }

        &-category-level-1,
        &-pages-list {
            > li {
                position: static;
                padding-right: 0;

                &:last-child {
                    padding-right: 0;
                }
            }
        }

        &-category-level-1 {
            > li {
                padding-right: 0;
            }
        }

        &-level-1-content,
        &-category-level-1 {
            height: $header-elements-full__height;
        }

        &-pages-list {
            height: $header-elements-full__height;
            margin-left: 0;

            > .menu-page-item {
                @include flexbox();
                @include align-items(center);

                > .menu-page-link {
                    @include flexbox();
                    height: $header-elements-full__height;
                    padding: 0 $base-padding * 0.5;
                    transition: $header-links__transition;

                    &::before {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        display: block;
                        width: $header-elements-full__width;
                        height: 0;
                        background-color: $header-navigation-main-container__background-color--large;
                        transition: $header-navigation-border__transition--large;
                    }

                    &:hover::before {
                        background-color: $header-navigation-item-border__background-color--large;
                    }
                }
            }

            > .menu-page-item:first-child {
                > .menu-page-link {
                    position: relative;

                    &::before {
                        width: calc(#{$header-elements-full__width} - #{$header-elements-base__padding});
                    }
                }
            }
        }

        &-category-level-1 {
            @include flex-wrap(nowrap);

            > li {
                @include flexbox();
                @include align-items(center);
                white-space: nowrap;

                // stylelint-disable max-nesting-depth
                &.selected {
                    > a {
                        background-color: $header-navigation-item-hover__background-color--large;
                        color: $header-navigation-item-hover__color--large;
                    }
                }

                &:hover {
                    .menu-level-2-container {
                        visibility: visible;
                        transition-delay: 0.3s;
                    }
                }

                > a {
                    @include flexbox();
                    height: $header-elements-full__height;
                    padding: 0 $base-padding * 0.6;
                    font-size: $header-navigation-category-link__font-size--large;
                    text-transform: uppercase;
                    transition: $header-links__transition;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        display: block;
                        width: $header-elements-full__width;
                        height: $header-navigation-border__height--large;
                        background-color: $header-navigation-main-container__background-color--large;
                        transition: $header-navigation-border__transition--large;
                    }

                    // &:hover::after {
                    //     background-color: $header-navigation-item-border__background-color--large;
                    // }
                }
            }

            .menu-content-blocks {
                // stylelint-disable selector-max-compound-selectors
                a {
                    padding: $base-padding * 0 $base-padding * 1;

                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        padding-right: 0;
                    }

                    &:hover {
                        background-color: transparent;
                        color: $header-navigation-item-hover__background-color--large;
                    }

                    img {
                        max-height: 11rem;
                    }
                }
            }
        }

        &-category-level-2,
        &-category-level-3 {
            > li {
                a {
                    color: $white;
                }
            }
        }

        &-level-1-container {
            position: static;
            padding: 0;
            margin: 0;
            transition: $header-menu__transition;
        }

        &-level-1-wrapper {
            @include flexbox();
            @include align-items(center);
            height: $header-navigation__height--large;
        }

        &-level-1-content {
            margin: 0;
        }

        &-level-2-container {
            position: absolute;
            z-index: 1;
            top: $header-navigation__height--large;
            left: 0;
            width: $header-elements-full__width;
            height: auto;
            padding: 0;
            margin: 0;
            background-color: $header-navigation-dropdown__background-color--large;

            &:focus-visible {
                outline: none; // Fixes Chrome user-agent style which adds extra boxing wrap.
            }
        }

        &-level-2-wrapper {
            position: relative;
        }

        &-level-2-item {
            &.selected {
                > a {
                    &::after {
                        background-color: $header-navigation-item-border__background-color--large;
                    }
                }
            }
        }

        &-level-2-inner-wrapper {
            @include flexbox();
            @include flex-wrap(wrap);
            padding: $header-navigation-second-level-inner__padding--medium-large;

            li {
                margin-right: $base-margin * 2;

                a {
                    display: block;
                    padding: $header-navigation-second-level-links__padding--large;
                    border-bottom: $header-navigation-second-level-links__border--large;
                    color: $header-navigation-second-level-links__color--large;
                    font-size: $header-navigation-link__font-size--large;
                    font-weight: $header-navigation-second-level-links__font-weight--large;
                    line-height: $header-navigation-link__line-height--large;

                    &.hide-brands-container {
                        display: none;
                    }
                }


                &.menu-view-brands-page {
                    padding-top: $header-navigation-view-brands-page__padding-top--large;

                    > a {
                        border-bottom: none;
                        background-color: transparent;
                        color: $header-navigation-view-brands-page__color--large;
                        font-weight: $header-navigation-first-categpry-level-link__font-weight--large;
                        text-decoration: $header-navigation-view-brands__text-decoration--large;
                    }
                }
            }

            // stylelint-disable selector-class-pattern
            .menu-category-level-2 {
                @include flexbox();
                @include flex-direction(column);
                @include flex-wrap(wrap);
                height: $header-menu-category-level-2__height--medium-large;

                li {
                    a {
                        font-size: $header-navigation-subcategory-link__font-size--medium-large;
                        font-weight: $header-navigation-first-categpry-level-link-bold__font-weight--large;
                    }
                }
            }
        }

        &-view-brands-page {
            > a {
                &:hover::after {
                    display: none;
                }
            }
        }

        &-level-2-content,
        &-dropdown-content,
        &-brands-content,
        &-blocks-content {
            @include flex($header-navigation-blocks__flex--large);
            margin: 0;
        }

        &-level-2-content {
            @include flex($header-navigation-menu-content-block__flex--large);
            padding-left: 0;
        }

        &-blocks-content {
            @include flex($header-navigation-menu-content-block__flex--large);
            padding-right: 0;
            margin-top: $base-margin * -0.9;
            margin-left: auto;
        }

        &-pages-container {
            height: $header-elements-full__height;
            margin: 0;
            margin-left: auto;

            .content-asset {
                height: $header-elements-full__height;
            }
        }

        &-brands-list {
            padding: 0;
            margin: 0;

            li {
                list-style-type: none;

                a {
                    &:hover {
                        background: transparent;
                        color: $orange;
                    }
                }
            }
        }

        &-dropdown-labels {
            padding: $header-navigation-dropdown-labels__padding--medium-large;

            &::after {
                display: none;
            }
        }

        &-content-blocks {
            @include flexbox();
            @include justify-content(flex-start);
            padding-bottom: $base-padding;

            > * {
                position: relative;
                padding: 0;

                .text-on-image {
                    @include get-font('compact');
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 100%;
                    text-align: center;
                    transform: translateY(-50%);
                }
            }
        }
    }
}

// IE compatibility
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .menu-level-2-inner-wrapper {
        @include flex-wrap(nowrap);
    }
}

@media screen and (min-width: $desktop-width-huge) {
    .menu {
        &-level-2-content,
        &-dropdown-content,
        &-brands-content,
        &-blocks-content {
            padding: $header-navigation-second-level-columns__padding--large;
        }
    }

    .menu-level-2-inner-wrapper {
        padding: $header-navigation-second-level-inner__padding--large;

        li {
            a {
                padding: $header-navigation-second-level-links__padding--large;
            }
        }

        .menu-category-level-2 {
            height: $header-menu-category-level-2__height--large;

            li {
                a {
                    font-size: $header-navigation-subcategory-link__font-size--large;
                }
            }

        }

    }

    .menu-blocks-content {
        .menu-dropdown-labels {
            border-top: none;
        }
    }

    .menu-dropdown-labels {
        padding: $header-navigation-dropdown-labels__padding--large;
        border-top: $border solid $header-navigation-second-level-labels__border-color--large;
    }
}

@media screen and (min-width: $desktop-width-x-large) and (max-width: $desktop-width-large) {
    .menu {
        &-main {
            a {
                font-size: $header-navigation-category-link__font-size-small--medium-large;
            }
        }

        .menu-level-1-wrapper {
            .menu-category-level-1,
            .menu-pages-list {
                > li {
                    padding-right: 0;
    
                    a {
                        padding: 0 0.7 * $base-padding;
                    }
                }
            }
        }



        &-pages-list {
            > .menu-page-item:first-child {
                > .menu-page-link {
                    padding-right: $header-elements-base__padding--medium-large;
                    font-size: $header-navigation-category-link__font-size-small--medium-large;

                    &::before {
                        width: calc(#{$header-elements-full__width} - #{$header-elements-base__padding--medium-large});
                    }
                }
            }

            > .menu-page-item {
                .menu-page-link {
                    padding: 0 $header-elements-base__padding--medium-large;
                }
            }
        }

        &-category-level-1 {
            > li {
                > a {
                    font-size: $header-navigation-category-link__font-size--medium-large;
                    padding: 0 $base-padding * 0.5;
                }
            }
        }
    }
}

@media screen and (min-width: $desktop-width-medium-large) and (max-width: $desktop-width-large) {
    .menu-category-level-1 > li > a {
        @include get-font('mini-xsmall');
        padding: 0 $base-padding * 0.5;
    }

    .menu-pages-list > .menu-page-item .menu-page-link,
    .menu-pages-list > .menu-page-item:first-child > .menu-page-link {
        @include get-font('mini');
        padding: 0 $base-padding * 0.5;
    }
}

@media screen and (min-width: $desktop-width-large) and (max-width: $desktop-width-huge) {
    .menu-category-level-1 > li > a {
        @include get-font('mini-xsmall');
        padding: 0 $base-padding * 0.5;
    }

    .menu-pages-list > .menu-page-item .menu-page-link,
    .menu-pages-list > .menu-page-item:first-child > .menu-page-link {
        @include get-font('mini-xsmall');
        padding: 0 $base-padding * 0.5;
    }
}

// =============================================================================
//
//  Product Recommendations Variables
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

$product-recommendation__margin: ($base-margin * 7) 0 0;
$product-recommendation-calloutmsg__margin-bottom: $base-margin;
$product-recommendation-calloutmsg__font-weight: $bolder;

$card-view-product__border: $border solid $pale-liliac;
$card-view-product__padding-top: $base-padding * 2;

// =============================================================================
// Large
// =============================================================================

$product-recommendation-grid__margin--large: 0 ($base-margin * -1);
$product-recommendation-product__width--large: 20%;
$product-recommendation-product__padding--large: 0 $base-padding;

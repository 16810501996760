// =============================================================================
//
//  Dropdown Desktop
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================


.dropdown__container {
    height: 6rem;
}

.dropdown {

    // -------------------------------------------------------------------------
    //  Dropdown Header:
    // -------------------------------------------------------------------------

    &-header>.title {
        @include get-font('medium');
        padding: ($base-padding * 1.55) ($base-padding * 5) ($base-padding * 1.55) 0;

        &::after {
            top: 0.9rem;
            font-size: 2.8rem;
            line-height: 1.38;
        }
    }
}
